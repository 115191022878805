import { render, staticRenderFns } from "./aigc.vue?vue&type=template&id=dd3c2148&scoped=true&"
import script from "./aigc.vue?vue&type=script&lang=js&"
export * from "./aigc.vue?vue&type=script&lang=js&"
import style0 from "./aigc.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./aigc.vue?vue&type=style&index=1&id=dd3c2148&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd3c2148",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VApp,VBanner,VCard,VImg})
